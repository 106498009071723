import React from 'react';

import '../css/common.css';

import { Table } from 'react-bootstrap';

import { TournamentData } from './variables';
import { TournamentProps } from './interfaces';

const Tournaments: React.FC<TournamentProps> = ({ tournaments }) => {
    return (
      <tbody>
        {/* eslint-disable-next-line array-callback-return */}
        {tournaments.map(tournament => (
              <tr>
                <td>{ tournament.date }</td>
                <td>{ tournament.lake }</td>
                <td>{ tournament.time }</td>
                <td><a href={ tournament.weigh_site.url } target="_blank" rel="noopener noreferrer">{ tournament.weigh_site.name }</a></td>
                <td><a href={ tournament.results_url } target="_blank" rel="noopener noreferrer">Results</a></td>
              </tr>              
            )
        )}
      </tbody>
    );
  };

export function Schedule() {
  return (
    <div>
      <Table className='table' striped bordered hover>
        <thead>
          <tr>
            <th>Date</th>
            <th>Lake</th>
            <th>Time</th>
            <th>Weigh-In Site</th>
            <th>Results</th>
          </tr>
        </thead>
        <Tournaments tournaments={TournamentData}></Tournaments>
      </Table>
    </div>
  );
}

export default Schedule;
