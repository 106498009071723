import React from 'react';
import { Nav, Navbar, NavDropdown } from 'react-bootstrap';

class CommonNavbar extends React.Component {
  render () {
    return (
      <Navbar bg='primary' variant='dark' fixed='top'>
        <Navbar.Brand href='/'>
          <img src='https://storage.googleapis.com/mlbc-assets/static/mlbc-logo.jpg' width='30' height='30' className='d-inline-block align-top' alt='MLBC Logo' />{' '}
          MLBC
        </Navbar.Brand>
        <Navbar.Toggle aria-controls='navbar-collapse' />
        <Navbar.Collapse id='navbar-collapse'>
          <Nav className='mr-auto'>
            <Nav.Link href='https://docs.google.com/document/d/1jrZKDKtcWNbUAbPP9Zhaogh5RHFjQauvk8pcyMnnVH4/edit?usp=sharing' target='_blank' rel='noopener noreferrer'>Club By-Laws</Nav.Link>
            <NavDropdown title='Tournaments' id='tournaments'>
              <NavDropdown.Item href='/schedule'>Schedule</NavDropdown.Item>
              <NavDropdown.Item href='https://docs.google.com/document/d/1pnScXJfWbLUJNRFDKfjaBXg75z1JA1hX8loeu_Modls/edit?usp=sharing' target='_blank' rel='noopener noreferrer'>Rules</NavDropdown.Item>
              <NavDropdown.Item href='/standings'>Point Standings</NavDropdown.Item>
            </NavDropdown>
            <Nav.Link href='/members'>Members</Nav.Link>
            <NavDropdown title='Meeting Minutes' id='minutes'>
              <NavDropdown.Item href='/minutes/2020'>2020</NavDropdown.Item>
              <NavDropdown.Item href='/minutes/2021'>2021</NavDropdown.Item>              
              <NavDropdown.Item href='/minutes/2022'>2022</NavDropdown.Item>   
              <NavDropdown.Item href='/minutes/2023'>2023</NavDropdown.Item>                   
              <NavDropdown.Item href='/minutes/2024'>2024</NavDropdown.Item>                               
            </NavDropdown>
            <NavDropdown title='Treasurer Reports' id='reports'>
              <NavDropdown.Item href='/treasuryreport/2020'>2020</NavDropdown.Item>
              <NavDropdown.Item href='/treasuryreport/2021'>2021</NavDropdown.Item>              
              <NavDropdown.Item href='/treasuryreport/2022'>2022</NavDropdown.Item>              
              <NavDropdown.Item href='/treasuryreport/2023'>2023</NavDropdown.Item>     
              <NavDropdown.Item href='/treasuryreport/2024'>2024</NavDropdown.Item>                       
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    );
  }
}

export default CommonNavbar;
