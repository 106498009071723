import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import { NavRouteProps } from './interfaces';
import CommonNavbar from '../navbar';

export const NavRoutes: React.FC<NavRouteProps> = ({ routes }) => {
  return (
    <BrowserRouter>
        <div>
          <CommonNavbar />
          <Routes>  
            {routes.map(route => (
                <Route key={route.path} path={route.path} element={route.element} />
            ))}
          </Routes>
        </div>
    </BrowserRouter>
  );
};


