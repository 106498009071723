export const EventVar = {
    meeting: {
        date: "October 3rd",
        time: "7 PM",
        location: "Bill Miller's"
    },
    tournament:
    {
      date: "September 21st",
      location: "Lake LBJ - Wirts Boat Ramp"
    }
  }  