import { RouteProps } from 'react-router-dom';
import Membership from '../members';
import { Minutes } from '../minutes';
import Schedule from '../schedule';
import TeamStandings from '../standings';
import { Treasury } from '../treasury';
import Header from '../home/header';
import { AboutUs } from '../home/about';
import { UpcomingEvents } from '../events';
import { Winners } from '../winners';

export const RoutesConfig: RouteProps[] = [
    { path: '/', element: <Home />},
    { path: '/members', element: <Membership />},
    { path: '/minutes/2020', element: <Minutes2020 /> },
    { path: '/minutes/2021', element: <Minutes2021 />},
    { path: '/minutes/2022', element: <Minutes2022 />},
    { path: '/minutes/2023', element: <Minutes2023 />},
    { path: '/minutes/2024', element: <Minutes2024 />},
    { path: '/schedule', element: <Schedule />},
    { path: '/standings', element: <TeamStandings />},
    { path: '/treasuryreport/2020', element: <Treasury2020 />},
    { path: '/treasuryreport/2021', element: <Treasury2021 />},
    { path: '/treasuryreport/2022', element: <Treasury2022 />},
    { path: '/treasuryreport/2023', element: <Treasury2023 />},
    { path: '/treasuryreport/2024', element: <Treasury2024 />},
];

// Minutes Elements
function Minutes2020() {
    return Minutes("2020")
}

function Minutes2021() {
    return Minutes("2021")
}

function Minutes2022() {
    return Minutes("2022")
}

function Minutes2023() {
    return Minutes("2023")
}

function Minutes2024() {
    return Minutes("2024")
}

// Treasury Report Elements
function Treasury2020() {
    return Treasury("2020")
}

function Treasury2021() {
    return Treasury("2021")
}

function Treasury2022() {
    return Treasury("2022")
}

function Treasury2023() {
    return Treasury("2023")
}

function Treasury2024() {
    return Treasury("2024")
}

// Home Page Elements
function Home() {
    return (
      <div>
        <div>
            <Header />
        </div>
        <div>
            <AboutUs />
        </div>
        <div>
            <Winners />
        </div>
        <div>
            <UpcomingEvents />
        </div>
      </div>
    )
}
