import React from 'react';

import '../css/common.css';

import { Table } from 'react-bootstrap';

import { ReportsProp } from './interfaces'
import { ReportsData } from './variables'

const ClubMinutes: React.FC<ReportsProp> = ({ group, reports }) => {
    return (
        <tbody>
        {reports.map(report =>
          /* eslint-disable-next-line array-callback-return */
          report.data.map(data => {
            if (report.year === group) {
                return (
                    <tr>
                        <td>{ data.month }</td>
                        <td><a href={ data.url } target="_blank" rel="noopener noreferrer">Report</a></td>
                    </tr>
                );
            }
          })
        )}
        </tbody>
    );
};

export function Minutes(year: string) {
    return (
    <div>
        <Table className='table' striped bordered hover>
        <thead>
            <tr>
            <th>Month</th>
            <th>Minutes</th>
            </tr>
        </thead>
        <ClubMinutes group={ year } reports={ ReportsData } />
        </Table>
    </div>
    );
}