import React from 'react';

import '../css/common.css';

import { Table } from 'react-bootstrap';

import { TeamsData } from './variables';
import sum from './variables';
import { TeamsProps } from './interfaces';

const Standings: React.FC<TeamsProps> = ({ teams }) => {
    return (
      <tbody>
        {/* eslint-disable-next-line array-callback-return */}
        {teams.map(team => (
              <tr>
                <td>{ team.members.join(' / ') }</td>
                <td>{ team.meeting_points }</td>
                <td>{ team.tournament_points }</td>
                <td>{ sum([team.meeting_points, team.tournament_points]) }</td>
              </tr>              
            )
        )}
      </tbody>
    );
  };

export function TeamStandings() {
  return (
    <div>
      <Table className='table' striped bordered hover>
        <thead>
          <tr>
            <th>Team</th>
            <th>Meeting</th>
            <th>Tournament</th>
            <th>Total</th>
          </tr>
        </thead>
        <Standings teams={TeamsData}></Standings>
      </Table>
    </div>
  );
}

export default TeamStandings;
