import { Report } from './interfaces';

/* Add new Data here and then add new function in
   src/router/variables.tsx 
*/
export const ReportsData: Report[] = [
  { year: "2020", 
    data: [
      { month: "January", url: "https://docs.google.com/document/d/1r0bglMmzXPCIsU4QW82djb_tXy31_msSvJJpzHAp4Zs/edit?usp=sharing"},
      { month: "February", url: "https://docs.google.com/document/d/1LGPOaEJZBRJD0EB0MDVpuRDbepthGfOVxcGYiUFhS28/edit?usp=sharing"},
      { month: "March", url: "https://docs.google.com/document/d/12BqLYpg1ptMdvLu-JaSHcaUyhwtAQJNOFufhOnbl_Lo/edit?usp=sharing"},
      { month: "April", url: ""},
      { month: "May", url: "https://docs.google.com/document/d/1tcLEW5SWUsDe0EZ3oBc0TiKKcT0jcW4kAHWTAsy1aXo/edit?usp=sharing"},
      { month: "June", url: "https://docs.google.com/document/d/1tyRpXByE_8q-DuDULmIw-7hDE45yM3ogD_1BoAAPFhw/edit?usp=sharing"},
      { month: "July", url: "https://docs.google.com/document/d/1-iIWLEBwmfsBVmxKLQ15aXUhXFee_AH7DqFaOHXdCaU/edit?usp=sharing"},
      { month: "August", url: ""},
      { month: "September", url: ""},
      { month: "October", url: ""},
      { month: "November", url: "https://docs.google.com/document/d/1LvkHZTfKax1wcPHqZHuHOfGLQR1BH-zGyg0vb6R4Zk8/edit?usp=sharing"},
      { month: "December", url: "https://docs.google.com/document/d/1WeuJ6hY03gCUphfRQOa2EkI4jkTSd9lSM4k3juXrpvY/edit?usp=sharing"},
    ],
  },
  { year: "2021",
    data: [
      { month: "January", url: "https://docs.google.com/document/d/1cMYgylKfAT7fIXtkAta9G_I8Y-TFXcFQjBHg8d4fU8Y/edit?usp=sharing"},
      { month: "February", url: "https://docs.google.com/document/d/1UOiOTi7S8ieGpNtgWdiYgfIWVScS37moBGZI97qZz-A/edit?usp=sharing"},
      { month: "March", url: "https://docs.google.com/document/d/1rV2zXrvD1XcjtDSX7NT-s9lciSxvFfuZ-zdrAbZFPZg/edit?usp=sharing"},
      { month: "April", url: "https://docs.google.com/document/d/1h-i_qW9u6RUI4mFFyx-w7TVkhDU2__e0DsrphmCE0Wk/edit?usp=sharing"},
      { month: "May", url: "https://docs.google.com/document/d/1YR9BDaiqLlCXWjW6_tlu8cSxxpjWC7SoyH83gNP7JF4/edit?usp=sharing"},
      { month: "June", url: "https://docs.google.com/document/d/1vvVXzb8z8rSrZoP5bvL-ei5aJkGhAWQl2gm1BhFd0gE/edit?usp=sharing"},
      { month: "July", url: "https://docs.google.com/document/d/14bPnh-9PiULPqwrCRi5t8wNRiov0QkM7z3l0kg_1hl0/edit?usp=sharing"},
      { month: "August", url: "https://docs.google.com/document/d/1Ep4citvlBO_JMP1QAY76pe0PneB4WZBIqmmfJlcwhKg/edit?usp=sharing"},
      { month: "September", url: "https://docs.google.com/document/d/14QeuqeOVvEJ_h172OwpOxrrDS95V0bZuxP93IryMnAI/edit?usp=sharing"},
      { month: "October", url: "https://docs.google.com/document/d/1RqgHsz9hWEq2eoLXqkZuJYl70jTinHy6dEROjemskEM/edit?usp=sharing"},
      { month: "November", url: "https://docs.google.com/document/d/12ObO_ijQQ0VR8J7JjIz_0Be5wQUeh-8f-ooJKUb8xTI/edit?usp=sharing"},
      { month: "December", url: "https://docs.google.com/document/d/1TlASCOtVwWHeXJPBXottAYLKR1vQeioFMyNhPqAOPo8/edit?usp=sharing"},    
    ]
  },
  { year: "2022",
    data: [
      { month: "January", url: "https://docs.google.com/document/d/1ijgN1Qw7tGya3BYUz4AybR9PWMZ6hQPwxKviLBSyXVs/edit?usp=sharing"},
      { month: "February", url: "https://docs.google.com/document/d/152iU9q3fUKUiCK02r2C1VvM-TrZy2WAQK6bFHZTbYnw/edit?usp=sharing"},
      { month: "March", url: "https://docs.google.com/document/d/1HLbtRdhF9kJR5fJYZmqzrrK4MFJIH_W-1Blb3kZNFMk/edit?usp=sharing"},
      { month: "April", url: "https://docs.google.com/document/d/1Bz__-U95LDLdLIjoLZxz8P0faWWLse5y-vaHWG00510/edit?usp=sharing"},
      { month: "May", url: "https://docs.google.com/document/d/1xDEzNPDPuoZrepto01lwboWxemJNZ0kTEGCufrO70Js/edit?usp=sharing"},
      { month: "June", url: "https://docs.google.com/document/d/11hECriPMBuYOOMpe-JBV5W9GDE3mdZt4k2ucCS2z-jA/edit?usp=sharing"},
      { month: "July", url: "https://docs.google.com/document/d/14ifsVhXMd6yK9h1mOouy7WCg1LnA5_fOD_-svFb-zWg/edit?usp=sharing"},
      { month: "August", url: "https://docs.google.com/document/d/1x8Cqugs9Wl5BJmpXEx4ZxzNUH8yVmNybDWlKgfZauj4/edit?usp=sharing"},
      { month: "September", url: "https://docs.google.com/document/d/1VnKjEU3xobD9mcdf96Qm8aXV1bRRtQxK6NYSGrb47-4/edit?usp=sharing"},
      { month: "October", url: "https://docs.google.com/document/d/1SIWYnA-3C4GI0uZTGpATPAyPvt0U9KSKhz1Sv7MlsRM/edit?usp=sharing"},
      { month: "November", url: "https://docs.google.com/document/d/11_WXs3WQvbQqz3CFYQ_fB9jhgYvZWo4bt7DSbO8Cr7I/edit?usp=sharing"},
      { month: "December", url: "https://docs.google.com/document/d/1B5_8j9snBjlPf8dX9dKeGjMeZM08zOLK0TtvhY1Bhj8/edit?usp=sharing"},    
    ]
  },
  { year: "2023",
    data: [
      { month: "January", url: "https://docs.google.com/document/d/18ySnxsKR5dPAydr7bkb6ppYGPO-d44YK65JKrx1b-PM/edit?usp=sharing"},
      { month: "February", url: "https://docs.google.com/document/d/1Zn-2zmGNOBsRK8QB_d9GDj720tTEmzx-e8uRXGFXQlk/edit?usp=sharing"},
      { month: "March", url: "https://docs.google.com/document/d/1-Cncwoo5uy8qGDupHRPzfpLHvfv0p7hCKYW9t4ZCX7Q/edit?usp=sharing"},
      { month: "April", url: "https://docs.google.com/document/d/1498Xn2IWJAoTHsw_b1Frmu0GEtr-ekEpy-GtqPSxG4Y/edit?usp=sharing"},
      { month: "May", url: "https://docs.google.com/document/d/1h3283ba8XFbPGtDbAG6DKyIR8vKfG9ds28pJFBp_kUQ/edit?usp=sharing"},
      { month: "June", url: "https://docs.google.com/document/d/1n3NqE8NRJFjWOwLyisymiplKd8zmscZgwtLu6ZTRTmg/edit?usp=sharing"},
      { month: "July", url: "https://docs.google.com/document/d/1_tcHxpaHg7IAAA1i9Wuq9RnMYw0M2eT96D24VN-8DzM/edit?usp=sharing"},
      { month: "August", url: "https://docs.google.com/document/d/1rvPO_146f5_y4beHPlWDkJmHhfk1OdbH433ny-oHMLs/edit?usp=sharing"},
      { month: "September", url: "https://docs.google.com/document/d/1PKpkQJCw3fZNFlv8qDTJPbsVg6Dvb54KQMUdB7GcWO0/edit?usp=sharing"},
      { month: "October", url: "https://docs.google.com/document/d/16ILpm0v14pq6Pq6lSc5cmfc9WLxxzmrrzq3ebM6Ja60/edit?usp=sharing"},
      { month: "November", url: "https://docs.google.com/document/d/1MsyTO1isQ9qydBZPe09I9Sjhg-7Ys9eAmLbrFtcxTbM/edit?usp=sharing"},
      { month: "December", url: "https://docs.google.com/document/d/15sgJI5LahEV6YBz7Kh2uvMuR-ECV6kWe2TwZSPoHeWI/edit?usp=sharing"},    
    ]
  },
  { year: "2024",
    data: [
      { month: "January", url: "https://docs.google.com/document/d/1trHCOzc8_b6gN0M4HoMrOIBuMb3e7QaK_qJiNS35hRY/edit?usp=sharing"},
      { month: "February", url: "https://docs.google.com/document/d/178f6gr-GIuuDegFuqsBFjF7H0TrsGSOs7bhnad1W3s0/edit?usp=sharing"},
      { month: "March", url: "https://docs.google.com/document/d/1-o3KFSMUTdLrspflQXh-Cfu1_w81akYMQGbPfPm5yrA/edit?usp=sharing"},
      { month: "April", url: "https://docs.google.com/document/d/1YoO3ppzlBaIPP0bOMkYyJHyIa0_g8fVITulEFaficJg/edit?usp=sharing"},
      { month: "May", url: "https://docs.google.com/document/d/1Ga4WphuChx2uRlrsLLC6V3PdX_Pa8KJHgUDqlMTGhN4/edit?usp=sharing"},
      { month: "June", url: "https://docs.google.com/document/d/17Pt7GBK2f-9yyRc7AmBuTYxEdro892y0RgNW6yiN-00/edit?usp=sharing"},
      { month: "July", url: "https://docs.google.com/document/d/1h0yLfyH4DZhnEbcpBmUfvyjdqHtNiU0ukTVR1AiskuA/edit?usp=sharing"},
      { month: "August", url: "https://docs.google.com/document/d/106cFg3rcFGP29RnGQWExAZpmB_HZU_5-Qg85Pwhe1yA/edit?usp=sharing"},
      { month: "September", url: "https://docs.google.com/document/d/11zELrX9k2ExIzzwR4XFc8QUk1-QWC9XamgU6WhiQf8g/edit?usp=sharing"},
      { month: "October", url: ""},
      { month: "November", url: ""},
      { month: "December", url: ""},    
    ]
  }
]