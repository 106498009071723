import React from 'react';

import '../css/common.css';

import { Table } from 'react-bootstrap';

import { PayoutsProp, ReportsProp } from './interfaces'
import { PayoutsData, ReportsData } from './variables'

const TreasuryReports: React.FC<ReportsProp> = ({ group, reports }) => {
    return (
        <tbody>
        {reports.map(report =>
          /* eslint-disable-next-line array-callback-return */
          report.data.map(data => {
            if (report.year === group) {
                return (
                    <tr>
                        <td>{ data.month }</td>
                        <td><a href={ data.url } target="_blank" rel="noopener noreferrer">Report</a></td>
                    </tr>
                );
            }
          })
        )}
        </tbody>
    );
};

const TreasuryPayouts: React.FC<PayoutsProp> = ({ payouts }) => {
    return (
      <tbody>
        {/* eslint-disable-next-line array-callback-return */}
        {payouts.map(payout => (
            <tr>
                <td>{ payout.type }</td>
                <td>{ payout.ammount }</td>
            </tr>
          )
        )}
      </tbody>
    );
};

export function Treasury(year: string) {
    return (
    <div>
        <h2>End of Year Payouts</h2>
        <Table className='table' striped bordered hover>
            <thead>
                <tr>
                    <th>Type</th>
                    <th>Ammount</th>
                </tr>
            </thead>
            <TreasuryPayouts payouts={ PayoutsData } />
        </Table>
        <Table className='table' striped bordered hover>
        <thead>
            <tr>
            <th>Month</th>
            <th>Report</th>
            </tr>
        </thead>
        <TreasuryReports group={ year } reports={ ReportsData } />
        </Table>
    </div>
    );
}