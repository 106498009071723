import React from 'react';
import { Card, CardGroup } from 'react-bootstrap';

export function Header() {
  return (
    <div>
      <CardGroup>
        <Card>
          <Card.Img src='https://storage.googleapis.com/mlbc-assets/static/mlbc-logo.jpg' alt='MLBC Logo' />
        </Card>
        <Card>
          <Card.Body>
            <Card.Title bsPrefix='main-page-title'>Medina Lake Bass Club</Card.Title>
            <Card.Text bsPrefix='main-page-text'>Est. 2007</Card.Text>
          </Card.Body>
        </Card>
        <Card>
          <Card.Img src='https://storage.googleapis.com/mlbc-assets/static/texas-flag.jpg' alt='Texas Flag' />
        </Card>
      </CardGroup>
    </div>
  );
}

export default Header;
