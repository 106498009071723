import { Tournament, WeighSite, Lake } from './interfaces';

const lakes: Lake[] = [
    { 
        name: "Lake LBJ",
        ramps: [
            { name: "Wirts Ramp", url: "https://goo.gl/maps/Xn3ZaswRVMXxcr4fA"}
        ]
    },
    {
        name: "Choke Canyon",
        ramps: [
            { name: "Chaliham", url: "https://maps.app.goo.gl/5uqDzZcqSWANy4J68"}
        ]
    },
    {
        name: "Canyon Lake", 
        ramps: [
            { name: "Potter's Creek", url: "https://maps.app.goo.gl/ZV3ofH75tBvAwXVz6"}
        ]
    },
    {
        name: "O.H. Ivie", 
        ramps: [
            { name: "Kennedy Boat Ramp", url: "https://maps.app.goo.gl/YnvoDesHmqcqfoU66"}
        ]
    },
    {
        name: "Lake Amistad", 
        ramps: [
            { name: "Diablo East", url: "https://maps.app.goo.gl/FR68X7uWbmCVB8E97"},
            { name: "Spur 454", url: "https://maps.app.goo.gl/2GaC9F5oUTPhi4aT7"}
        ]
    },
    {
        name: "Falcon Lake", 
        ramps: [
            { name: "County Ramp", url: "https://maps.app.goo.gl/JE3VWHMWs2qyGXT69"},
            { name: "State Park Ramp", url: "https://maps.app.goo.gl/sPZTPPtWb9Fg2DwR6"}
        ]
    },
    {
        name: "Buchanan Lake", 
        ramps: [
            { name: "Dam Ramp", url: "https://maps.app.goo.gl/JVkdpsYMy2nc5dS77"},
            { name: "Burnet County Park Ramp", url: "https://maps.app.goo.gl/oqAVxFqyq5E8c1GG8"},
            { name: "Llano County Park Ramp", url: "https://maps.app.goo.gl/oKj9CLiCprkWSzQH6"}
        ]
    },
]

// eslint-disable-next-line
const LBJ = lakes[0];
// eslint-disable-next-line
const Choke = lakes[1];
// eslint-disable-next-line
const CanyonLake = lakes[2];
// eslint-disable-next-line
const Ivie = lakes[3];
// eslint-disable-next-line
const Amistad = lakes[4];
// eslint-disable-next-line
const Falcon = lakes[5];
// eslint-disable-next-line
const Buchanan = lakes[6];

function getTournament(date: string, numDays: number, lake: string, weigh_site: WeighSite, results_url: string): Tournament {
    if (numDays > 1) {
        return { date: date, lake, time: "6:00 - 3:00 PM CT, 6:00 - 1:00 PM CT", weigh_site, results_url };
    }
    return { date, lake, time: "6:00 - 3:00 PM CST", weigh_site, results_url };
}

export const TournamentData: Tournament[] = [
    getTournament("February 24th", 1, Choke.name, Choke.ramps[0], "https://docs.google.com/spreadsheets/d/10AJyDL5LaOZIPlcmZSXnU7Pgo0s9YO2kE9x-xfWn4W0/edit?usp=sharing"),
    getTournament("March 9th", 1, Ivie.name, Ivie.ramps[0], "https://docs.google.com/spreadsheets/d/1IDZxtg0Fo1HMlG7eQHUXuvJ5b7smuGNlyLs5Hd_6f5c/edit?usp=sharing"),
    getTournament("March 10th", 1, Ivie.name, Ivie.ramps[0], "https://docs.google.com/spreadsheets/d/1rP5ovwgMZho0cbx8SpWFZv8YSUz-6oXnyAtx5xvXU2U/edit?usp=sharing"),
    getTournament("April 27th-28th", 2, Amistad.name, Amistad.ramps[0], "https://docs.google.com/spreadsheets/d/1tq2kq-Pbqwih7Uis61IR3Mfp0I0_YLAWQRqUL56ACg4/edit?usp=sharing"),
    getTournament("June 22nd", 1, Ivie.name, Ivie.ramps[0], "https://docs.google.com/spreadsheets/d/1-C5518CN3QheO8OuV5tLL9nybFDBKjP3ITQguBqDFnc/edit?usp=sharing"),
    getTournament("June 23nd", 1, Ivie.name, Ivie.ramps[0], "https://docs.google.com/spreadsheets/d/1O1jhA2t5z6TUP8kUX_YEg-GhAbbC7kpMeCWZakiFxF8/edit?usp=sharing"),
    getTournament("July 13th", 1, Buchanan.name, Buchanan.ramps[1], "https://docs.google.com/spreadsheets/d/1tMUlQH7YxJyC64_FQDI91giuCBt97f8QQAHSNVFzccs/edit?usp=sharing"),
    getTournament("August 17th", 1, Choke.name, Choke.ramps[0], "https://docs.google.com/spreadsheets/d/1U0-J6aScL7izuRXsTsAHhDIImFaLj6RjtQ3gxGeFijg/edit?usp=sharing"),
    getTournament("September 14th", 1, LBJ.name, LBJ.ramps[0], "https://docs.google.com/spreadsheets/d/1VIXA4WoX8YXC-xc9JarMEMcrYbKn5n0lCglesBN7mTM/edit?usp=sharing"),
    getTournament("October 26-27th", 2, Ivie.name, Ivie.ramps[0], "https://docs.google.com/spreadsheets/d/1O1jhA2t5z6TUP8kUX_YEg-GhAbbC7kpMeCWZakiFxF8/edit?usp=sharing"),
    getTournament("November 16th", 1, LBJ.name, LBJ.ramps[0], "https://docs.google.com/spreadsheets/d/1O1jhA2t5z6TUP8kUX_YEg-GhAbbC7kpMeCWZakiFxF8/edit?usp=sharing"),

]
