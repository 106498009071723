import * as React from 'react';
import { EventVar } from './variables' 

var meeting = EventVar.meeting
var tournament = EventVar.tournament

export function UpcomingEvents() {
  return (
  <div>
    <h2>Upcoming Events!</h2>
    <p>
    Meeting:
    <br />
    { meeting.date } @ { meeting.time } - { meeting.location }
    <br />
    Tournaments:
    <br />
    { tournament.date } at { tournament.location }
    </p>
  </div>
  )
}
