import * as React from 'react';

import '../css/common.css';

import { Table } from 'react-bootstrap';

import { MemberData, OfficerData } from './variables';
import { MemberProps, OfficerProps } from './interfaces';

const CharterMembers: React.FC<MemberProps> = ({ members }) => {
    return (
      <tbody>
        {/* eslint-disable-next-line array-callback-return */}
        {members.map(member => {
          if (member.isCharter) {
            return (
                <tr><td>{ member.first } { member.last }</td></tr>
                
            );
         }
        })}
      </tbody>
    );
};

const ClubMembers: React.FC<MemberProps> = ({ members }) => {
    return (
        <tbody>
        {/* eslint-disable-next-line array-callback-return */}
        {members.map(member => {
            if (member.isMember) {
            return (
                <tr><td>{ member.first } { member.last }</td></tr>
            );
            }
        })}
        </tbody>
    );
};

const ClubOfficers: React.FC<OfficerProps> = ({ officers }) => {
    return (
        <tbody>
        {officers.map(officer => {
            return (
                <tr><td>{ officer.position } - { officer.name }</td></tr>
            );
            }
        )}
        </tbody>
    );
};

export function Membership() {
    return (
        <div>
            <Table className='table' striped bordered hover>
            <thead>
                <tr>
                <th>Officers</th>
                </tr>
            </thead>
            <ClubOfficers officers={OfficerData}></ClubOfficers>
            </Table>
            <Table className='table' striped bordered hover>
            <thead>
                <tr>
                <th>Charter Members</th>
                </tr>
            </thead>
            <CharterMembers members={MemberData}></CharterMembers>
            </Table>
            <Table className='table' striped bordered hover>
            <thead>
                <tr>
                <th>Members</th>
                </tr>
            </thead>
            <ClubMembers members={MemberData}></ClubMembers>
            </Table>
        </div>
    );
}

export default Membership;
