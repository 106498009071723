import React from 'react';

export function AboutUs() {  // eslint-disable-line no-unused-vars
  return <p>
           The purpose of the Medina Lake Bass Club is to promote friendly competition among fellow members while
           enjoying fellowship, a friendly exchange of fishing techniques and ideas, and a true understanding of the
           conservation of Natures surroundings and of our fishing resources. We also hope to share these ideas with future
           generations, leaving them all the rewards and benifits of our experience, sportsmanship and fishing knowledge.
           Membership is by recommendation only so please contact one of
           the <a className='App-link' href='/members'>club officers</a> if you have an interest in joining our club.
         </p>
}
