import './css/app.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import { NavRoutes } from './router';
import { RoutesConfig } from './router/variables'

import ReactGA from 'react-ga';
window.onload = function () {
  ReactGA.initialize('UA-155283567-1');
  ReactGA.pageview(window.location.pathname + window.location.search);
}

function App() {
  return (
    <div className="App">
      <header className="App-header">
      <NavRoutes routes={ RoutesConfig }></NavRoutes>
      </header>
    </div>
  );
}

export default App;
