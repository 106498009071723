import { Member, Officer } from './interfaces';

export const MemberData: Member[] = [
    { first: "Chuck", last: "Kersch", isCharter: false, isMember: true },
    { first: "Don", last: "Rogers", isCharter: true, isMember: false },
    { first: "Evelyn", last: "Thienpont", isCharter: true, isMember: false },
    { first: "Henry", last: "Vera", isCharter: true, isMember: true },
    { first: "Henry", last: "Vera Jr", isCharter: false, isMember: true },
    { first: "Isaac", last: "Rogers", isCharter: false, isMember: true },
    { first: "Jacob", last: "Saenz", isCharter: true, isMember: true },
    { first: "Jimmy", last: "Kessler", isCharter: true, isMember: false },
    { first: "John", last: "Trevino", isCharter: false, isMember: true },
    { first: "Karl", last: "Rogers", isCharter: false, isMember: true },
    { first: "Scott", last: "Zook", isCharter: true, isMember: false },
    { first: "Shawn", last: "Warren", isCharter: false, isMember: true },
];

export const OfficerData: Officer[] = [
    { position: "President", name: "Don Rogers" },
    { position: "Vice-President", name: "Jacob Saenz" },
    { position: "Sergeant at Arms", name: "Chuck Kersch" },
    { position: "Secretary", name: "Shawn Warren" },
    { position: "Treasurer", name: "Shawn Warren" },
    { position: "Tournament Director", name: "Chuck Kersch, John Trevino" },
    { position: "Webmaster", name: "Shawn Warren" },
];